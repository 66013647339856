/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// const imagesPrefix =
//   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

import cplus from "./cplusw.png";
import gpa from "./gpaw.png";

export default [
  {
    title: "Free Apps",
    description: "Click to go download from the Play Store.",
    items: [
      {
        image: gpa,
        padding: true,
        name: "Grandpa's Cribbage",
        description: "Play cribbage and use the analyzer to improve you game!",
        route: "https://play.google.com/store/apps/details?id=com.grandpascribbage.free",
      },
      // {
      //   image: `${imagesPrefix}/headers.jpg`,
      //   name: "Grandpa's Cribbage Vintage",
      //   description: "The original version and the only one that has Switcheroo mode!",
      //   route: "/sections/page-sections/features",
      // },
      {
        image: cplus,
        name: "Cribbage Card Counter",
        description: "Learn to count hands in cribbage or solve a dispute while playing!",
        route: "https://play.google.com/store/apps/details?id=cribbagecardcounter.cribbage",
      },
    ],
  },
];
// export default [
//   {
//     title: "Grandpas's Cribbage 2.0",
//     description: "The newest version with improved graphics and sound!",
//     items: [
//       {
//         image: `${imagesPrefix}/headers.jpg`,
//         name: "Page Headers",
//         count: 10,
//         route: "/sections/page-sections/page-headers",
//       },
//       {
//         image: `${imagesPrefix}/features.jpg`,
//         name: "Features",
//         count: 14,
//         route: "/sections/page-sections/features",
//       },
//       {
//         image: `${imagesPrefix}/pricing.jpg`,
//         name: "Pricing",
//         count: 8,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/faq.jpg`,
//         name: "FAQ",
//         count: 1,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/blogs.jpg`,
//         name: "Blog Posts",
//         count: 11,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/testimonials.jpg`,
//         name: "Testimonials",
//         count: 11,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/teams.jpg`,
//         name: "Teams",
//         count: 6,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/stats.jpg`,
//         name: "Stats",
//         count: 3,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/call-to-action.jpg`,
//         name: "Call to Actions",
//         count: 8,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/projects.jpg`,
//         name: "Applications",
//         count: 6,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/logo-area.jpg`,
//         name: "Logo Areas",
//         count: 4,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/footers.jpg`,
//         name: "Footers",
//         count: 10,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/general-cards.jpg`,
//         name: "General Cards",
//         count: 9,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/content-sections.jpg`,
//         name: "Content Sections",
//         count: 8,
//         pro: true,
//       },
//     ],
//   },
//   {
//     title: "Grandpas's Cribbage Classic",
//     description: "The original version and the only one that has Switcheroo mode!",
//     items: [
//       {
//         image: `${imagesPrefix}/navbars.jpg`,
//         name: "Navbars",
//         count: 4,
//         route: "/sections/navigation/navbars",
//       },
//       {
//         image: `${imagesPrefix}/nav-tabs.jpg`,
//         name: "Nav Tabs",
//         count: 2,
//         route: "/sections/navigation/nav-tabs",
//       },
//       {
//         image: `${imagesPrefix}/pagination.jpg`,
//         name: "Pagination",
//         count: 3,
//         route: "/sections/navigation/pagination",
//       },
//     ],
//   },
//   {
//     title: "Cribbage Card Counter",
//     description: "Learn to count hands in cribbage or solve a dispute while playing!",
//     items: [
//       {
//         image: `${imagesPrefix}/newsletters.jpg`,
//         name: "Newsletters",
//         count: 6,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/contact-sections.jpg`,
//         name: "Contact Sections",
//         count: 8,
//         pro: true,
//       },
//       {
//         image: `${imagesPrefix}/forms.jpg`,
//         name: "Forms",
//         count: 3,
//         route: "/sections/input-areas/forms",
//       },
//       {
//         image: `${imagesPrefix}/inputs.jpg`,
//         name: "Inputs",
//         count: 6,
//         route: "/sections/input-areas/inputs",
//       },
//     ],
//   },
// ];
