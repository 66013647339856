/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/designBlocksData";

const Header = () => (
  <Container>
    <Grid
      container
      item
      xs={12}
      lg={6}
      flexDirection="column"
      alignItems="center"
      sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
    >
      <MKBadge
        variant="contained"
        color="info"
        //badgeContent="Infinite combinations"
        badgeContent="A tradition"
        container
        sx={{ mb: 2 }}
      />
      <MKTypography variant="h2" fontWeight="bold">
        {/* Huge collection of sections */}
        Grandpa's Cribbage
      </MKTypography>
      <MKTypography variant="body1" color="text">
        {/* We have created multiple options for you to put together and customise into pixel
            perfect pages. */}
        Cribbage is more than a game, it is a tradition passed down to us by our grandpa's.
      </MKTypography>
    </Grid>
  </Container>
);

function DesignBlocks() {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={12}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Grid container spacing={3}>
          {items.map(({ image, name, count, route, description, padding }) => (
            <Grid item xs={12} md={6} sx={{ mb: 2 }} key={name}>
              <a href={route} target="_blank">
                <ExampleCard image={image} name={name} count={count} description={description} padding={padding} />
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      {/* <Header />
      <Container sx={{ mt: 6 }}>{renderData}</Container> */}
      <Container>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
